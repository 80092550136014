import React from 'react';
import { Card, CardContent, Grid, CardMedia, Typography, Divider, Button } from '@mui/material';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import { Box } from '@mui/system';
import Rating from '@mui/material/Rating';
import translations from '../../assets/data/translations'

function SmallCarCard(props) {
  const { image, title, rating, capacity, luggage, price, handleNext, disabled, language } = props;
  const lang = translations[language]

  return (
    <Card sx={{ maxWidth: '100%', marginTop: '2em', boxShadow: '20px 20px 20px rgba(0, 0, 0, 0.1)' }}>
      <CardContent>
        <Grid container direction="column">
          <Grid item>
            <CardMedia
              component="img"
              height="200"
              image={image}
              alt="car"
              style={disabled ? { opacity: '0.5' } : {}}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" style={disabled ? { opacity: '0.6' } : {}}>
              {title}
            </Typography>
            <Box 
              component="div" 
              display="flex" 
              alignItems="center" 
              marginTop={'0.6rem'} 
              marginBottom={'0.6rem'}
            >
              <Typography variant="body1" style={disabled ? { opacity: '0.6' } : {}}>{rating}</Typography>
              <Rating name="half-rating-read" defaultValue={parseFloat(rating)} precision={0.1} readOnly style={{marginLeft: '0.3rem'}}/>
            </Box>
            <Box display="flex" alignItems="center" marginBottom='0.3rem'>
                <PermIdentityOutlinedIcon style={{marginRight: '0.4rem', opacity: disabled ? '0.6' : '1' }}/>
                <Typography style={disabled ? { opacity: '0.6' } : {}}>{capacity}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
                <LuggageOutlinedIcon style={{marginRight: '0.4rem', opacity: disabled ? '0.6' : '1' }}/>
                <Typography style={disabled ? { opacity: '0.6' } : {}}>{luggage}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ margin: '1em 0' }} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" style={disabled ? { opacity: '0.6' } : {}}>
              {lang.price}:
            </Typography>
            <Typography variant="h6" color="primary" sx={{ marginBottom: '1rem' }} style={disabled ? { opacity: '0.6' } : {}}>
              {price}
            </Typography>
            <Button variant="contained" color="primary" fullWidth disabled={disabled} onClick={handleNext}>
              {lang.select}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default SmallCarCard;
