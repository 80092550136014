import React from 'react';
import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField, Typography, Grid, Button, Select, MenuItem, FormHelperText, Autocomplete, Box  } from '@mui/material';
import { prefixes } from '../../assets/data/prefixes';
import translations from '../../assets/data/translations'
import env_url from '../../assets/data/env_url';
const DepartureForm = ({ formValues, setFormValues, errors, selectedPlan, fromAddress, toAddress, journeyDate, journeyTime, returnDate, returnTime, personNumber, tabValue, language }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      // Submit the form
      console.log('Form submitted:', formValues);
    }
  };

  const lang = translations[language]
  const validateForm = () => {
    const newErrors = {
      flightNumber: false,
      landingTime: false,
      returnFlightNumber: false,
      returnLandingTime: false,
      seggiolino: false,
      termini: formValues.termini === false,
      seggiolinoPerNeonati: false,
      salutation: formValues.salutation === '',
      firstName: formValues.firstName === '',
      lastName: formValues.lastName === '',
      email: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email),
      phoneNumber: formValues.phoneNumber === '',
      phonePrefix: formValues.phonePrefix === '',
      note: false,
    };
  
    // if (formValues.landingTime === '') {
    //   newErrors.landingTime = true;
    // }
    // if (formValues.returnLandingTime === '') {
    //   newErrors.returnLandingTime = true;
    // }
  
    return newErrors;
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center" marginTop="2rem" marginX="0.5rem" width='90%'>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.5rem'}}>{lang.fromAdressForm}:</Typography>
          <Typography variant="body1" style={{ fontFamily: 'Roboto', fontWeight: 600, fontSize: '1.2rem'}}>{fromAddress}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">{lang.flightNumber}</Typography>
          <TextField
            id="flightNumber"
            name="flightNumber"
            value={formValues.flightNumber}
            onChange={handleChange}
            error={errors.flightNumber}
            helperText={errors.flightNumber && lang.required}
            fullWidth
          />
        </Grid>

        {/* <Grid item xs={12} sm={6} md={2}>
          <Typography variant="subtitle1">{lang.landingTime}</Typography>
          <TextField
            type="time"
            id="landingTime"
            name="landingTime"
            value={formValues.landingTime}
            onChange={handleChange}
            helperText={errors.landingTime && lang.required}
            error={errors.landingTime}
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid> */}

        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.5rem'}}>{lang.toAdressForm}</Typography>
          <Typography variant="body1" style={{ fontFamily: 'Roboto', fontWeight: 600, fontSize: '1.2rem'}}>{toAddress}</Typography>
        </Grid>

        {
          tabValue !== 0 && (
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">{lang.returnFlightNumber}</Typography>
              <TextField
                id="returnFlightNumber"
                name="returnFlightNumber"
                value={formValues.returnFlightNumber}
                onChange={handleChange}
                error={errors.returnFlightNumber}
                helperText={errors.returnFlightNumber && lang.required}
                fullWidth
              />
            </Grid>
          )
        }

        {/* {
          tabValue !== 0 && (
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="subtitle1">{lang.returnLandingTime}</Typography>
              <TextField
                type="time"
                id="returnLandingTime"
                name="returnLandingTime"
                value={formValues.returnLandingTime}
                helperText={errors.returnLandingTime && lang.required}
                error={errors.returnLandingTime}
                onChange={handleChange}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )
        } */}

        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.5rem'}}>{lang.extra}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="seggiolino"
                checked={formValues.seggiolino}
                onChange={handleChange}
              />
            }
            label={lang.kidSeats}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="seggiolinoPerNeonati"
                checked={formValues.seggiolinoPerNeonati}
                onChange={handleChange}
              />
            }
            label={lang.kidSeatsPlus}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" marginY="1rem" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.5rem'}}>
            {lang.contactInfo}:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioGroup
                id="salutation"
                name="salutation"
                value={formValues.salutation}
                onChange={handleChange}
                row
              >
                <FormControlLabel value="Man" control={<Radio />} label="Sig." />
                <FormControlLabel value="Woman" control={<Radio />} label="Sig.ra" />
              </RadioGroup>
              {errors.firstName && (
                 <Typography variant="body2" color="error">
                    {lang.required}
                 </Typography>
               )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.3rem'}}>{lang.name}</Typography>
              <TextField
                id="firstName"
                name="firstName"
                value={formValues.firstName}
                onChange={handleChange}
                error={errors.firstName}
                helperText={errors.firstName && lang.required}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.3rem'}}>{lang.surname}</Typography>
              <TextField
                id="lastName"
                name="lastName"
                value={formValues.lastName}
                onChange={handleChange}
                error={errors.lastName}
                helperText={errors.lastName && lang.required}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.3rem'}}>{lang.email}</Typography>
              <TextField
                id="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                error={errors.email}
                helperText={errors.email && lang.requiredMail}
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.3rem'}}>{lang.prefix}</Typography>
              <Autocomplete
                id="phonePrefix"
                options={prefixes}
                getOptionLabel={(option) => option.label}
                value={formValues.phonePrefix === '' ? {
                  "label": "",
                  "value": ""
                } : formValues.phonePrefix}
                onChange={(event, newValue) => {
                  setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    phonePrefix: newValue ? newValue : '',
                  }));
                }}
                renderInput={(params) => <TextField {...params} error={errors.phonePrefix} />}
              />
              {errors.phonePrefix && <FormHelperText error>{lang.required}</FormHelperText>}
            </Grid>

            <Grid item xs={7}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.3rem'}}>{lang.phone}</Typography>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                value={formValues.phoneNumber}
                onChange={handleChange}
                error={errors.phoneNumber}
                helperText={errors.phoneNumber && lang.required}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.3rem'}}>{lang.note}</Typography>
              <TextField
                id="note"
                name="note"
                value={formValues.note}
                onChange={handleChange}
                error={errors.note}
                helperText={errors.note && lang.required}
                fullWidth
                multiline
                InputProps={{
                  sx: {
                    height: '7rem',
                    '& .MuiInputBase-input': {
                      height: '7rem',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="termini"
                      checked={formValues.termini}
                      onChange={handleChange}
                      error={errors.termini}
                    />
                  }
                  label={
                    <>
                      <span style={{marginRight: '1em', fontSize: '1.1em' }}>{lang.terminiServizi}</span>
                      <a
                        href={env_url.pdf_url}
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ 
                          textDecoration: 'none', 
                          color: '#000d6b', 
                          fontWeight: 'bold', 
                          fontSize: '1.2em' 
                        }}>
                        {lang.visualizzaPdf}
                      </a>
                    </>
                  }
                />
                {errors.termini && <Typography variant="caption" color="error" style={{ marginLeft: '15px' }}>{lang.required}</Typography>}
              </Box>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

      <Button type="submit" style={{ display: 'none' }}>Submit</Button>
    </form>
  );
};

export default DepartureForm;
