import React, { useState, useEffect } from "react";
import { Button, Grid, Tab, Tabs, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import PersonSelect from "./PersonSelect";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DateTimeField from "./DateTimePicker";
import AddressField from "./AdressField";
import { useNavigate  } from "react-router-dom";
import FormTabs from "./FormTabs"
import ReturnForm from "./ReturnForm";
import OneWayForm from "./OneWayForm";
import translations from "../../assets/data/translations"


const StyledForm = styled('form')({
  position: 'absolute',
  top: '13rem',
  left: '50%',
  variant: 'scrollable',
  transform: 'translateX(-50%)',
  zIndex: 11,
  width: '80%', // adjust this value as needed
  maxWidth: '1400px', // adjust this value as needed
  background: '#fff',
  boxShadow: '5px 5px 15px -5px #7c8a97',
  padding: '40px', // increase the padding to create more space
  borderRadius: '10px', // adding border radius
  fontSize: '1.6rem', // increase the font size
  '@media (max-width:600px)': {
    top: '6.5rem', // adjust this value as needed
  },
});

const getCurrentDateTime = (format) => {
  const now = new Date();

  let month = (now.getMonth() + 1).toString();
  if (month.length === 1) month = '0' + month;

  let day = now.getDate().toString();
  if (day.length === 1) day = '0' + day;

  let hours = now.getHours().toString();
  if (hours.length === 1) hours = '0' + hours;

  let minutes = now.getMinutes().toString();
  if (minutes.length === 1) minutes = '0' + minutes;

  const date = `${now.getFullYear()}-${month}-${day}`;
  const time = `${hours}:${minutes}`;
  const dateTime = `${date}T${time}`;

  switch (format) {
    case 'date':
      return date;
    case 'time':
      return time.slice(0, 5);
    default:
      return dateTime;
  }
}


const FindCarForm = ({
  fromAddress,
  setFromAddress,
  toAddress,
  setToAddress,
  journeyDate,
  setJourneyDate,
  journeyTime,
  setJourneyTime,
  returnDate,
  setReturnDate,
  returnTime,
  setReturnTime,
  tabValue,
  setTabValue,
  personNumber,
  setPersonNumber,
  setActiveStep,
  fromAddressOptions,
  setFromAddressOptions,
  toAddressOptions,
  setToAddressOptions,
  language,
  setScrollPosition,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [timeErrorMessage, setTimeErrorMessage] = useState("");
  const lang = translations[language]

  const [errors, setErrors] = useState({
    fromAddress: false,
    toAddress: false,
    journeyDate: false,
    journeyTime: false,
    returnDate: false,
    returnTime: false,
    personNumber: false
  });
  

  const handleCercaClick = () => {

    const journeyDateTime = new Date(`${journeyDate}T${journeyTime}`);
    let returnDateTime = null;
    if (returnDate) {
      returnDateTime = new Date(`${returnDate}T${returnTime}`);
    }
    const now = new Date();
    const timeDifference = journeyDateTime - now;
    const returnTimeDifference = returnDateTime ? returnDateTime - now : null;

    let errors = {
      fromAddress: fromAddress === null,
      toAddress: toAddress === null,
      journeyDate: journeyDate === '',
      journeyTime: journeyTime === '',
      returnDate: tabValue === 1 && (!returnDate || returnDate === ''),
      returnTime: tabValue === 1 && returnTime === '',
      personNumber: personNumber === '',
    }

    if(journeyTime === '' || timeDifference < 24 * 60 * 60 * 1000) {
      setTimeErrorMessage(lang.timeErrorMessages.journeyTimeErr)
      setOpenSnackbar(true);
      return
    }

    if (returnDate === journeyDate) {
      if (returnTimeDifference < 24 * 60 * 60 * 1000) {
        setTimeErrorMessage(lang.timeErrorMessages.returnTimeErr)
          setOpenSnackbar(true);
          return;
      }
      if (returnTime <= journeyTime) {
          setTimeErrorMessage(lang.timeErrorMessages.returnTimeErrPrec)
          setOpenSnackbar(true);
          return;
      }
  }

    setErrors(errors);
    console.warn('Errors:', errors)
    // check if there are any errors
    let hasErrors = Object.values(errors).some(value => value === true);
    if (!hasErrors) {
      let newFromAdress = typeof(fromAddress) === 'object' ? fromAddress["start_point"] : fromAddress
      let newToAdress = typeof(toAddress) === 'object' ? toAddress["end_point"] : toAddress
      setActiveStep(1)
      if (tabValue == 0){
        console.warn(
          `POST TO API:\n` +
          `fromAddress: ${newFromAdress}\n` +
          `toAddress: ${newToAdress}\n` +
          `journeyDate: ${journeyDate}\n` +
          `journeyTime: ${journeyTime}\n` +
          `personNumber: ${personNumber}\n` +
          `tabValue: ${tabValue}\n`+
          `language: ${language}\n`
        );  
      }else{
        console.warn(
          `POST TO API:\n` +
          `fromAddress: ${newFromAdress}\n` +
          `toAddress: ${newToAdress}\n` +
          `journeyDate: ${journeyDate}\n` +
          `journeyTime: ${journeyTime}\n` +
          `returnDate: ${returnDate}\n` +
          `returnTime: ${returnTime}\n` +
          `personNumber: ${personNumber}\n` +
          `tabValue: ${tabValue}\n`+
          `language: ${language}\n`
        );  
      }
      prepareTransferUrl(newFromAdress, newToAdress);
    }
  }


  const prepareTransferUrl = (newFromAdress, newToAdress) => {
    let url = "/transfer?";
    if (tabValue === 0) {
      url += `fromAddress=${encodeURIComponent(
        fromAddress ? newFromAdress : ""
      )}&toAddress=${encodeURIComponent(
        toAddress ? newToAdress : ""
      )}&journeyDate=${encodeURIComponent(journeyDate)}&journeyTime=${encodeURIComponent(
        journeyTime
      )}&personNumber=${encodeURIComponent(
        personNumber
      )}&tabValue=${encodeURIComponent(
        tabValue
      )}`;
      // )}&language=${encodeURIComponent(language)}`;
    } else {
      url += `fromAddress=${encodeURIComponent(
        fromAddress ? newFromAdress : ""
      )}&toAddress=${encodeURIComponent(
        toAddress ? newToAdress : ""
      )}&journeyDate=${encodeURIComponent(journeyDate)}&journeyTime=${encodeURIComponent(
        journeyTime
      )}&returnDate=${encodeURIComponent(returnDate)}&returnTime=${encodeURIComponent(
        returnTime
      )}&personNumber=${encodeURIComponent(
        personNumber
      )}&tabValue=${encodeURIComponent(
        tabValue
      )}`;
      // )}&language=${encodeURIComponent(language)}`;
    }
    navigate(url);
    setScrollPosition(matches ? 400 : 700)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // useEffect(() => {
  //   if (fromAddressOptions.length === 0 && fromAddress) {
  //     const matchingFromOption = {
  //       "id": "",
  //       "start_point": fromAddress,
  //       "end_point": fromAddress,
  //       "route_distance": "",
  //       "route_time": ""
  //     };
  //     setFromAddressOptions([matchingFromOption]);
  //   }
  
  //   if (toAddressOptions.length === 0 && toAddress) {
  //     const matchingToOption = {
  //       "id": "",
  //       "start_point": toAddress,
  //       "end_point": toAddress,
  //       "route_distance": "",
  //       "route_time": ""
  //     };
  //     setToAddressOptions([matchingToOption]);
  //   }
  // }, []);
  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ marginTop: '4rem', padding: '1rem 1rem' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error" variant="filled" sx={{ fontSize: '1.4rem' }}>
            {timeErrorMessage}
        </Alert>
      </Snackbar>
      <StyledForm>
      <FormTabs tabValue={tabValue} handleTabChange={handleTabChange} language={language}/>
      {tabValue === 1 ? (
        <ReturnForm
          fromAddress={fromAddress}
          setFromAddress={setFromAddress}
          setFromAddressOptions={setFromAddressOptions}
          setToAddressOptions={setToAddressOptions}
          fromAddressOptions={fromAddressOptions}
          toAddress={toAddress}
          setToAddress={setToAddress}
          toAddressOptions={toAddressOptions}
          errors={errors}
          journeyDate={journeyDate}
          setJourneyDate={setJourneyDate}
          journeyTime={journeyTime}
          setJourneyTime={setJourneyTime}
          returnDate={returnDate}
          setReturnDate={setReturnDate}
          returnTime={returnTime}
          setReturnTime={setReturnTime}
          personNumber={personNumber}
          setPersonNumber={setPersonNumber}
          handleCercaClick={handleCercaClick}
          language={language}
        />
      ) : (
        <OneWayForm
          fromAddress={fromAddress}
          setFromAddress={setFromAddress}
          setFromAddressOptions={setFromAddressOptions}
          setToAddressOptions={setToAddressOptions}
          fromAddressOptions={fromAddressOptions}
          toAddress={toAddress}
          setToAddress={setToAddress}
          toAddressOptions={toAddressOptions}
          errors={errors}
          journeyDate={journeyDate}
          setJourneyDate={setJourneyDate}
          journeyTime={journeyTime}
          setJourneyTime={setJourneyTime}
          personNumber={personNumber}
          setPersonNumber={setPersonNumber}
          handleCercaClick={handleCercaClick}
          language={language}
        />
      )}
    </StyledForm>
    </>
  );
};

export default FindCarForm;
