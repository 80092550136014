import React from "react";
import { Col, Row, Container } from "reactstrap";
import { styled } from '@mui/material/styles';
import "../../styles/services-list.css";
import servicesData from "../../assets/data/serviceData";
import translations from '../../assets/data/translations';

// Define your styled component here
const StyledDiv = styled('div')({
  margin: '0',  
  padding: '20px 50px', 
  borderRadius: '10px',  
  // backgroundColor: '#0476F1',
  backgroundColor: '#024d9f',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
});

const ServicesList = ({language}) => {
  const translatedServicesData = translations[language].serviceData;

  return (
    <StyledDiv>
      {translatedServicesData.map((item) => (
        <ServiceItem item={item} key={item.id} />
      ))}
    </StyledDiv>
  );
};

const ServiceItem = ({ item }) => (
  <Col lg="4" md="6" sm="6" className="mb-3">
    <div className="service__item">
      <span className="mb-3 d-inline-block icon-container">
        <i style={{paddingLeft: '3px'}} className={item.icon} />
      </span>

      <h6 className="section__title_services" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '1.6rem'}}>{item.title}</h6>
      <p className="section__description" style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '1.2rem', paddingRight: '1rem'}}>{item.desc}</p>
    </div>
  </Col>
);

export default ServicesList;
