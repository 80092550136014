import React from 'react';
import { Autocomplete, TextField, InputAdornment } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { styled } from '@mui/system';
import { useEffect } from 'react';

const StyledTextField = styled(TextField)({
  "& input": {
    height: '2.4375em !important',
  },
});

const AddressAutocomplete = ({ type, value, options, onInputChange, label, isLoading, onChange, onFocus }) => {
    // useEffect(() => {
    //   console.warn("OPTIONS HEREEE ->>",options[1].start_point); // Check the value of options
    //   console.warn("VALUE HEREEE ->>",value); // Check the value of options
    //   console.warn("EQAULITY HEREEE ->>",options[1].start_point === value); // Check the value of options
    // }, [options]);
  
    return (
      <Autocomplete
        value={value}
        options={options}
        onInputChange={onInputChange}
        getOptionLabel={(option) => type === 'to' ? option.end_point : option.start_point}
        loading={isLoading}
        filterOptions={(x) => x}
        renderInput={(params) =>
          <StyledTextField
            {...params}
            label={label}
            required
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
            onFocus={onFocus}
          />
        }
        onChange={onChange}
        isOptionEqualToValue={(option, value) => {
            const point = type === 'to' ? option.end_point : option.start_point;
            const valuePoint = type === 'to' ? value.end_point : value.start_point;

            if (typeof value === "string") {
              return point === value;
            } else if (typeof value === "object") {
              return point === valuePoint;
            }
            return false;
          }}
      />
    );
  };

export default AddressAutocomplete;
