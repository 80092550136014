import React from "react";

import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import "../../styles/hero-slider.css";

const HeroSlider = ({tabValue}) => {
  const settings = {
    fade: true,
    speed: 2000,
    autoplaySpeed: 3000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <Slider {...settings} className="hero__slider">

      {tabValue === 0 ? (
        <div className="slider__item slider__item-01 mt0"></div>
      ) : (
        <div className="slider__item__mobile  slider__item-01 mt0"></div>
      )}
      {/*<div className="slider__item slider__item-02 mt0"></div>*/}

      {/*<div className="slider__item slider__item-03 mt0"></div>*/}
    </Slider>
  );
};

export default HeroSlider;
