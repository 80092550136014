import { Box, Select, MenuItem, InputAdornment } from '@mui/material';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import React, { useState } from "react";

const PersonSelect = ({ personNumber, setPersonNumber }) => {
  const handleChange = (event) => {
    setPersonNumber(event.target.value);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Select
        value={personNumber}
        onChange={handleChange}
        inputProps={{
          style: { 
            textAlign: 'center',
            width: '2rem',
            marginLeft: '2rem',
          },
        }}
        sx={{ height: '4.55rem' }}
        startAdornment={
          <InputAdornment position="start">
            <PersonOutlineOutlined />
          </InputAdornment>
        }
        MenuProps={{
            disablePortal: false,
        }}
      >
        {[...Array(8).keys()].map((i) => (
          <MenuItem value={i+1} key={i}>{i+1}</MenuItem>
        ))}
        {/*<MenuItem value={9}>8+</MenuItem>*/}
      </Select>
    </Box>
  );
};

export default PersonSelect;
