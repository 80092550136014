import React from 'react';
import { Card, CardContent, Grid, CardMedia, Typography, Divider, Button } from '@mui/material';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import Rating from '@mui/material/Rating';
import { Box } from '@mui/system';
import translations from '../../assets/data/translations'

function CarCard(props) {
    const { image, title, rating, capacity, luggage, price, handleNext, language } = props;
    const lang = translations[language]
    return (
        <Card sx={{ maxWidth: '100%', marginTop: '2em', boxShadow: '20px 20px 20px rgba(0, 0, 0, 0.1)',}}>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <CardMedia
                    component="img"
                    height="200"
                    image={image}
                    alt="car"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h5">
                    {title}
                    </Typography>
                    <Box 
                    component="div" 
                    display="flex" 
                    alignItems="center" 
                    marginTop={'0.6rem'} 
                    marginBottom={'0.6rem'}
                    >
                        <Typography variant="body1" style={{marginRight: '0.3rem'}}>{rating}</Typography>
                        <Rating name="half-rating-read" defaultValue={parseFloat(rating)} precision={0.1} readOnly />
                    </Box>
                    <Box display="flex" alignItems="center" marginBottom='0.3rem'>
                        <PermIdentityOutlinedIcon style={{marginRight: '0.4rem' }}/>
                        <Typography>{capacity}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <LuggageOutlinedIcon style={{marginRight: '0.4rem' }}/>
                        <Typography>{luggage}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box display="flex" alignItems="center">
                        <WorkspacePremiumOutlinedIcon style={{marginRight: '0.4rem' }}/>
                        <Typography>{lang.professionality}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <HandshakeOutlinedIcon style={{marginRight: '0.4rem' }}/>
                        <Typography>{lang.meeting}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <TimerOutlinedIcon style={{marginRight: '0.4rem' }}/>
                        <Typography>{lang.waiting}</Typography>
                    </Box>
                    <Divider style={{ margin: '1em 0' }} />
                    <Typography variant="h6">
                        {lang.price}:
                    </Typography>
                    <Typography variant="h6" sx={{ marginBottom: '1rem' }}  color="primary">
                    {price}
                    </Typography>
                    <Button variant="contained" color="primary" fullWidth onClick={handleNext}>
                        {lang.select}
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
        </Card>
    );
}

export default CarCard;