import React, { useEffect } from "react";
import { Button, Grid } from '@mui/material';
import AddressField from "./AdressField";
import DateTimeField from "./DateTimePicker";
import PersonSelect from "./PersonSelect";
import { errorMessageClass } from "./AdressField";
import translations from '../../assets/data/translations'

const OneWayForm = ({
    fromAddress,
    setFromAddress,
    setFromAddressOptions,
    setToAddressOptions,
    fromAddressOptions,
    toAddress,
    setToAddress,
    toAddressOptions,
    errors,
    journeyDate,
    setJourneyDate,
    journeyTime,
    setJourneyTime,
    personNumber,
    setPersonNumber,
    handleCercaClick,
    language
  }) => {
    useEffect(() => {
      // console.warn("JOURNEY TIME HEREEE", journeyTime)
    },[journeyTime])
    const lang = translations[language]
    const resetJourneyTime = () => {
      setJourneyTime("");
    };
    return (
      <Grid container spacing={3} alignItems="center">
        <AddressField
          label={lang.fromAddress}
          type="from"
          address={fromAddress}
          setAddress={setFromAddress}
          setAddressOptions={setFromAddressOptions}
          adressOptions={fromAddressOptions}
          fromAddress={fromAddress}
          toAddress={toAddress}
          error={errors.fromAddress}
          language={language}
        />
        <AddressField
          label={lang.toAddress}
          type="to"
          address={toAddress}
          setAddress={setToAddress}
          setAddressOptions={setToAddressOptions}
          adressOptions={toAddressOptions}
          fromAddress={fromAddress}
          toAddress={toAddress}
          error={errors.toAddress}
          language={language}
        />
        <DateTimeField
          label={lang.journeyDate}
          type="date"
          mdValue={3}
          dateTime={journeyDate}
          setDateTime={setJourneyDate}
          error={errors.journeyDate}
          language={language}
          resetJourneyTime={resetJourneyTime}
          artype="andataOneWay"
        />
        <DateTimeField
          label={lang.journeyTime}
          type="time"
          mdValue={3}
          dateTime={new Date().setHours(parseInt(journeyTime.split(":")[0]), parseInt(journeyTime.split(":")[1]))}
          selectedDateProp={journeyDate}
          setDateTime={setJourneyTime}
          error={errors.journeyTime}
          language={language}
        />
        <Grid item xs={12} sm={6} md={6} lg={1}>
          <PersonSelect
            personNumber={personNumber}
            setPersonNumber={setPersonNumber}
          />
          {errors.personNumber && <div style={errorMessageClass}>{lang.required}</div>}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={5}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleCercaClick}
          >
            {lang.search}
          </Button>
        </Grid>
      </Grid>
    );
  };

  export default OneWayForm;
