import React, { Fragment, useState } from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Routers from "../../routers/Routers";
import ScrollToTop from "../UI/ScrollToTop";

const Layout = () => {
  const [language, setLanguage] = useState('Italian');
  const [scrollPosition, setScrollPosition] = useState(0);

  return (
    <Fragment>
      <Header language={language} setLanguage={setLanguage}/>
      <ScrollToTop scrollPosition={scrollPosition}/>
      <div>
        <Routers language={language} setLanguage={setLanguage} setScrollPosition={setScrollPosition}/>
      </div>
      <Footer language={language}/>
    </Fragment>
  );
};

export default Layout;
