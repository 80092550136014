import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/car-item.css";

const CarItem = (props) => {
  const { imgUrl, model, carName, automatic, speed, price } = props.item;

  return (
    <Col lg="3" md="6" sm="6" className="mb-5">
      <div className="car__item">
        <div className="car__img" style={{marginTop:'1.3rem'}}>
          <img src={imgUrl} alt="" className="w-100 car__image"/>
        </div>

        <div className="car__item-content mt-4">
          <h4 className="section__title text-center"  style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '2rem', marginBottom: '-1.4rem' }}>{carName}</h4>

          <div className="car__item-info d-flex align-items-center justify-content-center mt-4 mb-2" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.2rem', color: '#7c8a97' }}>
            <span className="d-flex align-items-center gap-2" style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '1.4rem', color: '#7c8a97' }}>
              <i className="ri-car-line" style={{ fontSize: '2.5rem', paddingBottom:'0.8rem'}}></i> {model}
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default CarItem;
