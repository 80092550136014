
import Pisa from '../all-images/pisa.jpg';
import Firenze from '../all-images/firenze.jpg';
import Roma from '../all-images/roma.jpg';
import Milano from '../all-images/milano.jpg';
import Catania from '../all-images/catania.jpg';
import Bologna from '../all-images/bologna.jpg';
import img01 from "../all-images/cars-img/resized_economy_sedan.png";
import img02 from "../all-images/cars-img/firstclass.png";
import img03 from "../all-images/cars-img/standard_van.png";
import img04 from "../all-images/cars-img/first_van.png";



const data ={
    "Italian": {
        "Home": "Prenota",
        "Services": "Servizi",
        "Plans": "Piani",
        "How It Works": "Come funziona",
        "Contacts": "Contatti",
        "howItWorks": "Come funziona",
        "PopularCitiesFooter": "Citta Popolari",
        "TestimonialsFooter": "Testimonianze",
        "seeOur": "Guarda il nostro",
        "popularServices": "Servizi Popolari",
        "comfortAndSafety": "Massimo comfort e sicurezza per il tuo viaggio",
        "popularCities": "Città popolari",
        "popularCitiesDex": "Prenotazione rapida aereoporto-centro",
        "licensedVehicles": "Veicoli con licenza, conducenti professionisti",
        "ourClientsSay": "Cosa dicono i nostri clienti",
        "testimonials": "Testimonianze",
        "fromAddress": "Da indirizzo",
        "toAddress": "A indirizzo",
        "journeyDate": "Data di viaggio *",
        "journeyTime": "Orario di viaggio",
        "returnDate": "Data di ritorno",
        "returnTime": "Orario di ritorno",
        "required": "*obbligatorio",
        "requiredMail": "*Indirizzo e-mail non valido",
        "search": "Cerca",
        "terminiServizi": "Accetta termini e condizioni",
        "visualizzaPdf": "Visualizza",
        "quickLinks": "Link Rapidi",
        "headOffice": "Sede Centrale",
        "streetName": "VIA TORRETA 173, ACIREALE (CT) 95024, SICILIA",
        "officePhone": "+393921774905",
        "officeMail": "info@mytransfertrip.com",
        "officeTime": "9:00 - 17:00",
        "oneWayForm": "Solo Andata",
        "returnForm": "Andata e ritorno",
        "professionality": "Professionalità",
        "meeting": "Incontro e accoglienza sul posto",
        "waiting": "Tempo di attesa gratuito",
        "price": "Prezzo",
        "select": "Seleziona",
        "fromAdressForm": 'Partenza da',
        "toAdressForm": 'Arrivo a',
        "flightNumber": 'Num. del volo di andata',
        "landingTime": 'Orario andata',
        "returnFlightNumber": 'Num. del volo di ritorno',
        "returnLandingTime": 'Orario ritorno',
        "extra": 'Equipaggiamento ed extra',
        "kidSeats": 'Seggiolino per bambini (0-18 kg)',
        "kidSeatsPlus": 'Alza-bimbo (15-36 kg)',
        "contactInfo": 'Qualcosa su di te', 
        "name": 'Nome',
        "surname": 'Cognome',
        "email": 'Email',
        "prefix": 'Prefisso',
        "phone": 'Numero di telefono',
        "note": 'Note',
        "andrea": "Lavoro sempre in viaggio e uso molto i taxi. Da quando ho scoperto myTransferTrip, non riesco più a farne a meno! Arrivo sempre a destinazione in orario e senza stress. Lo consiglio vivamente!",
        "francesca": "Sono stata a Milano per la settimana della moda. myTransferTrip ha reso il mio viaggio senza problemi, dalla raccolta in aeroporto alle corse verso il centro della città. Servizio impeccabile!",
        "giovanni": "Viaggio spesso tra Roma e Napoli per lavoro. Con myTransferTrip, il viaggio lungo e stancante diventa piacevole e rilassante. Non cambierò mai piu questo servizio credo almeno per un po!",
        "maria": "Ho usato myTransferTrip durante una vacanza con la mia famiglia a Firenze. Ci hanno accompagnato dall'aeroporto al centro con tanta professionalità. Esperienza molto positiva!",
        "footer": "Offriamo un servizio superiore di noleggio con conducente, specializzato in trasferimenti dall'aeroporto al centro città e viceversa. Offriamo inoltre servizi di trasporto di lunga distanza. Con un team di professionisti esperti, ci impegniamo a fornire un servizio tempestivo, efficiente e di alta qualità. Per ulteriori informazioni, contatta il nostro servizio clienti.",

        "timeErrorMessages": {
            "journeyTimeErr": "L'orario di andata deve essere in anticipo di almeno 24h",
            "returnTimeErr": "L'orario di ritorno deve essere in anticipo di almeno 24h",
            "returnTimeErrPrec": "L'orario di ritorno deve essere successivo a quello di andata"
        },


        "serviceData": [
            {
                "id": 1,
                "title": "Pagamenti online",
                "icon": "ri-bank-card-line",
                "desc": "Vivi transazioni senza problemi con il nostro sistema di pagamento online sicuro ed efficiente. Goditi un processo senza intoppi dalla prenotazione al pagamento."
            },
            {
                "id": 2,
                "title": "Puntualità",
                "icon": "ri-alarm-line",
                "desc": "Il tempo è essenziale e il nostro servizio è orgoglioso di essere sempre puntuale. Fidati di noi per arrivare dove devi essere in orario."
            },
            {
                "id": 3,
                "title": "Monitoraggio dei voli",
                "icon": "ri-flight-takeoff-line",
                "desc": "I nostri servizi di monitoraggio dei voli assicurano che siamo sempre aggiornati sui dettagli del tuo volo. Vivi un viaggio senza stress con aggiornamenti tempestivi."
            },
            {
                "id": 4,
                "title": "Autisti",
                "icon": "ri-car-line",
                "desc": "I nostri autisti professionali e cortesi sono qui per offrirti un viaggio comodo e sicuro. Viaggia con personale esperto."
            },
            {
                "id": 5,
                "title": "Incontro e saluto",
                "icon": "ri-user-3-line",
                "desc": "Vivi un servizio di incontro e saluto caloroso e accogliente che ti fa sentire valutato dal momento in cui arrivi. Un volto amichevole ti aspetta."
            },
            {
                "id": 6,
                "title": "Azienda affidabile",
                "icon": "ri-shield-star-line",
                "desc": "Scegli un'azienda che rappresenta affidabilità ed eccellenza. Ci impegniamo a fornire servizi che superano le tue aspettative."
            }
        ],
        

        "steps": [
            {
                "title": "Inserisci il tuo percorso e seleziona il veicolo",
                "description": "Inserisci tutti i dati richiesti nel campo di ricerca e poi scegli il veicolo desiderato"
            },
            {
                "title": "Completa il modulo di prenotazione",
                "description": "Inserisci i dettagli del passeggero principale, aggiungi degli extra se lo desideri. Procedi al pagamento e ricevi il voucher"
            },
            {
                "title": "Incontra il tuo autista fuori dall'aeroporto",
                "description": "Riceverai i dettagli del tuo autista 6 ore prima del ritiro e ti attenderà sul posto con un cartello indicante il nome"
            }
        ],
        "cityData": [
            { "image": Pisa, "city": "Pisa", "description": "Aereoporto Pisa -> Pisa Centro", "fromAddress":"Aeroporto Internazionale di Pisa (PSA), Pisa, PI, Italia", "toAddress":"Pisa, PI, Italia" },
            { "image": Firenze, "city": "Firenze", "description": "Aereoporto Firenze -> Firenze Centro", "fromAddress":"Aeroporto Amerigo Vespucci, Via del Termine, Firenze, FI, Italia", "toAddress":"Centro Storico, Firenze, FI, Italia" },
            { "image": Roma, "city": "Roma", "description": "Aereoporto Roma -> Roma Centro", "fromAddress":"Aeroporto di Roma-Fiumicino 'Leonardo da Vinci' (FCO), Via dell'Aeroporto, Fiumicino, Roma, Italia", "toAddress":"Centro Storico, Roma, RM, Italia" },
            { "image": Milano, "city": "Milano", "description": "Aereoporto Milano -> Milano Centro", "fromAddress":"Aeroporto di Milano-Linate (LIN), Viale Enrico Forlanini, Segrate, Milano, Italia", "toAddress":"Centrale, Milano, MI, Italia" },
            { "image": Catania, "city": "Catania", "description": "Aereoporto Catania -> Catania Centro", "fromAddress":"Aeroporto di Catania, Via Fontanarossa, Catania, CT, Italia", "toAddress":"Centro, Catania, CT, Italia" },
            { "image": Bologna, "city": "Bologna", "description": "Aereoporto Bologna -> Bologna Centro", "fromAddress":"Aeroporto di Bologna, Via del Triumvirato, Bologna, BO, Italia", "toAddress":"Bologna, BO, Italia" }
        ],
        "carData": [
            {
                "id": 1,
                "carName": "Economica",
                "model": "Fino a 3 passeggeri",
                "imgUrl": img01,
                "capacity": "Ospita 3 persone",
                "luggage": "Adatto per fino a 3 valigie medie",
            },
            {
                "id": 2,
                "carName": "Business",
                "model": "Fino a 3 passeggeri",
                "imgUrl": img02,
                "capacity": "Ospita 3 persone",
                "luggage": "Adatto per fino a 3 valigie medie",
            },
            {
                "id": 3,
                "carName": "Mini Van",
                "model": "Fino a 7 passeggeri",
                "imgUrl": img03,
                "capacity": "Ospita 7 persone",
                "luggage": "Adatto per fino a 7 valigie medie",
            },
            {
                "id": 4,
                "carName": "Mini Bus",
                "model": "Fino a 8 passeggeri",
                "imgUrl": img04,
                "capacity": "Ospita 8 persone",
                "luggage": "Adatto per fino a 8 valigie medie",
            }
        ]

    },
    "English": {
        "Home": "Home",
        "Services": "Services",
        "Plans": "Plans",
        "How It Works": "How It Works",
        "Contacts": "Contacts",
        "howItWorks": "How It Works",
        "PopularCitiesFooter": "Popular Cities",
        "TestimonialsFooter": "Testimonials",
        "seeOur": "See our",
        "popularServices": "Popular Services",
        "comfortAndSafety": "Maximum comfort and safety for your trip",
        "popularCities": "Popular cities",
        "popularCitiesDex": "Quick airport-to-center booking",
        "licensedVehicles": "Licensed vehicles, professional drivers",
        "ourClientsSay": "Our clients say",
        "testimonials": "Testimonials",
        "fromAddress": "From address",
        "toAddress": "To address",
        "journeyDate": "Journey date *",
        "journeyTime": "Journey time",
        "returnDate": "Return date",
        "returnTime": "Return time",
        "required": "*obligatory",
        "requiredMail": "*Invalid e-mail address",
        "search": "Search",
        "terminiServizi": "Accept terms and conditions",
        "visualizzaPdf": "View",
        "quickLinks": "Quick Links",
        "headOffice": "Head Office",
        "streetName": "VIA TORRETA 173, ACIREALE (CT) 95024, SICILIA",
        "officePhone": "+393921774905",
        "officeMail": "info@mytransfertrip.com",
        "officeTime": "9:00 - 17:00",
        "oneWayForm": "One Way",
        "returnForm": "Round trip",
        "professionality": "Professionalism",
        "meeting": "Meeting and greeting on-site",
        "waiting": "Free waiting time",
        "price": "Price",
        "select": "Select",
        "fromAdressForm": 'Departure from',
        "toAdressForm": 'Arrival to',
        "flightNumber": 'Outgoing flight number',
        "landingTime": 'Departure time',
        "returnFlightNumber": 'Return flight number',
        "returnLandingTime": 'Return time',
        "extra": 'Equipment and extras',
        "kidSeats": "Child seat (0-18 kg)",
        "kidSeatsPlus": "Booster seat (15-36 kg)",
        "contactInfo": 'Something about you', 
        "name": 'First name',
        "surname": 'Last name',
        "email": 'Email',
        "prefix": 'Prefix',
        "phone": 'Phone number',
        "note": 'Note',
        "andrea": "I'm always traveling for work and use taxis a lot. Since I discovered myTransferTrip, I can't do without it! I always arrive on time and stress-free. I highly recommend it!",
        "francesca": "I was in Milan for Fashion Week. myTransferTrip made my journey hassle-free, from airport pickup to city center rides. Impeccable service!",
        "giovanni": "I often travel between Rome and Naples for work. With myTransferTrip, the long and tiring journey becomes enjoyable and relaxing. I will never change this service!",
        "maria": "I used myTransferTrip during a vacation with my family in Florence. They took us from the airport to the city center with so much professionalism. Very positive experience!",
        "footer": "We provide a superior chauffeur service, specializing in transfers from the airport to the city center and vice versa. We also offer long-distance transportation services. With a team of experienced professionals, we are committed to providing timely, efficient, and high-quality service. For further information, please contact our customer service.",

        "timeErrorMessages": {
            "journeyTimeErr": "The departure time must be at least 24h in advance",
            "returnTimeErr": "The return time must be at least 24h in advance",
            "returnTimeErrPrec": "The return time must be after the departure time"
        },

        "serviceData": [
            {
                "id": 1,
                "title": "Online payments",
                "icon": "ri-bank-card-line",
                "desc": "Experience hassle-free transactions with our secure and efficient online payment system. Enjoy a seamless process from booking to payment."
            },
            {
                "id": 2,
                "title": "Punctuality",
                "icon": "ri-alarm-line",
                "desc": "Time is of the essence, and our service prides itself on being punctual at all times. Trust us to get you where you need to be on time."
            },
            {
                "id": 3,
                "title": "Flight Monitoring",
                "icon": "ri-flight-takeoff-line",
                "desc": "Our flight monitoring services ensure that we are always updated with your flight details. Experience a stress-free journey with timely updates."
            },
            {
                "id": 4,
                "title": "Drivers",
                "icon": "ri-car-line",
                "desc": "Our professional drivers are here to provide you with a comfortable and safe journey. Travel with experienced personnel."
            },
            {
                "id": 5,
                "title": "Meet & Greet",
                "icon": "ri-user-3-line",
                "desc": "Experience a warm and welcoming meet and greet service that makes you feel valued from the moment you arrive. A friendly face awaits you."
            },
            {
                "id": 6,
                "title": "Reliable Company",
                "icon": "ri-shield-star-line",
                "desc": "Choose a company that stands for reliability and excellence. We are committed to providing services that exceed your expectations."
            }
        ],
        

        "steps": [
            {
                "title": "Enter your route and select the vehicle",
                "description": "Enter all required data in the search field and then choose the desired vehicle"
            },
            {
                "title": "Complete the booking form",
                "description": "Enter the main passenger details, add extras if you wish. Proceed to payment and receive the voucher"
            },
            {
                "title": "Meet your driver outside the airport",
                "description": "You will receive your driver's details 6 hours before pickup and he will be waiting for you on the spot with a sign indicating the name"
            }
        ],
        "cityData": [
            { "image": Pisa, "city": "Pisa", "description": "Pisa Airport -> Pisa City Center", "fromAddress":"Aeroporto Internazionale di Pisa (PSA), Pisa, PI, Italia", "toAddress":"Pisa, PI, Italia" },
            { "image": Firenze, "city": "Florence", "description": "Florence Airport -> Florence Center", "fromAddress":"Aeroporto Amerigo Vespucci, Via del Termine, Firenze, FI, Italia", "toAddress":"Centro Storico, Firenze, FI, Italia" },
            { "image": Roma, "city": "Rome", "description": "Rome Airport -> Rome City Center", "fromAddress":"Aeroporto di Roma-Fiumicino 'Leonardo da Vinci' (FCO), Via dell'Aeroporto, Fiumicino, Roma, Italia", "toAddress":"Centro Storico, Roma, RM, Italia" },
            { "image": Milano, "city": "Milan", "description": "Milan Airport -> Milan City Center", "fromAddress":"Aeroporto di Milano-Linate (LIN), Viale Enrico Forlanini, Segrate, Milano, Italia", "toAddress":"Centrale, Milano, MI, Italia" },
            { "image": Catania, "city": "Catania", "description": "Catania Airport -> Catania City Center", "fromAddress":"Aeroporto di Catania, Via Fontanarossa, Catania, CT, Italia", "toAddress":"Centro, Catania, CT, Italia" },
            { "image": Bologna, "city": "Bologna", "description": "Bologna Airport -> Bologna City Center", "fromAddress":"Aeroporto di Bologna, Via del Triumvirato, Bologna, BO, Italia", "toAddress":"Bologna, BO, Italia" }
        ],          
        "carData": [
            {
                "id": 1,
                "carName": "Economy",
                "model": "Up to 3 passengers",
                "imgUrl": img01,
                "capacity": "Accommodates 3 people",
                "luggage": "Suitable for up to 3 medium-sized suitcases"
            },
            {
                "id": 2,
                "carName": "Business",
                "model": "Up to 3 passengers",
                "imgUrl": img02,
                "capacity": "Accommodates 3 people",
                "luggage": "Suitable for up to 3 medium-sized suitcases"
            },
            {
                "id": 3,
                "carName": "Mini Van",
                "model": "Up to 7 passengers",
                "imgUrl": img03,
                "capacity": "Accommodates 7 people",
                "luggage": "Suitable for up to 7 medium-sized suitcases"
            },
            {
                "id": 4,
                "carName": "Mini Bus",
                "model": "Up to 8 passengers",
                "imgUrl": img04,
                "capacity": "Accommodates 8 people",
                "luggage": "Suitable for up to 8 medium-sized suitcases"
            }
        ]
    },
    "Spanish": {
        "Home": "Hogar",
        "Services": "Servicios",
        "Plans": "Planes",
        "How It Works": "Cómo funciona",
        "Contacts": "Contactas",
        "howItWorks": "Cómo funciona",
        "PopularCitiesFooter": "Ciudades Populares",
        "TestimonialsFooter": "Testimonios",
        "seeOur": "Mira nuestro",
        "popularServices": "Servicios Populares",
        "comfortAndSafety": "Máximo confort y seguridad para tu viaje",
        "popularCities": "Ciudades populares",
        "popularCitiesDex": "Reserva rápida desde el aeropuerto hasta el centro",
        "licensedVehicles": "Vehículos con licencia, conductores profesionales",
        "ourClientsSay": "Nuestros clientes dicen",
        "testimonials": "Testimonios",
        "fromAddress": "Desde dirección",
        "toAddress": "Hacia dirección",
        "journeyDate": "Fecha de viaje *",
        "journeyTime": "Hora de viaje",
        "returnDate": "Fecha de regreso",
        "returnTime": "Hora de regreso",
        "required": "*obligatorio",
        "requiredMail": "*Dirección de correo electrónico no válida",
        "search": "Buscar",
        "terminiServizi": "Aceptar terminos y condiciones",
        "visualizzaPdf": "Vista",
        "quickLinks": "Enlaces Rápidos",
        "headOffice": "Sede Central",
        "streetName": "VIA TORRETA 173, ACIREALE (CT) 95024, SICILIA",
        "officePhone": "+393921774905",
        "officeMail": "info@mytransfertrip.com",
        "officeTime": "9:00 - 17:00",
        "oneWayForm": "Solo Ida",
        "returnForm": "Ida y vuelta",
        "professionality": "Profesionalidad",
        "meeting": "Encuentro y bienvenida en el lugar",
        "waiting": "Tiempo de espera gratuito",
        "price": "Precio",
        "select": "Seleccionar",
        "fromAdressForm": 'Salida de',
        "toAdressForm": 'Llegada a',
        "flightNumber": 'Número de vuelo de ida',
        "landingTime": 'Hora de salida',
        "returnFlightNumber": 'Número de vuelo de regreso',
        "returnLandingTime": 'Hora de regreso',
        "extra": 'Equipamiento y extras',
        "kidSeats": "Asiento para niños (0-18 kg)",
        "kidSeatsPlus": "Asiento elevador para niños (15-36 kg)",
        "contactInfo": 'Algo sobre ti', 
        "name": 'Nombre',
        "surname": 'Apellido',
        "email": 'Correo electrónico',
        "prefix": 'Prefijo',
        "phone": 'Número de teléfono',
        "note": 'Nota',
        "andrea": "Siempre estoy viajando por trabajo y uso mucho los taxis. Desde que descubrí myTransferTrip, ¡no puedo vivir sin él! Siempre llego a tiempo y sin estrés. ¡Lo recomiendo mucho!",
        "francesca": "Estuve en Milán para la Semana de la Moda. myTransferTrip hizo mi viaje sin problemas, desde la recogida en el aeropuerto hasta los trayectos al centro de la ciudad. ¡Servicio impecable!",
        "giovanni": "Viajo con frecuencia entre Roma y Nápoles por trabajo. Con myTransferTrip, el largo y agotador viaje se convierte en un viaje agradable y relajante. ¡Nunca cambiaré este servicio!",
        "maria": "Utilicé myTransferTrip durante unas vacaciones con mi familia en Florencia. Nos llevaron del aeropuerto al centro de la ciudad con gran profesionalidad. ¡Experiencia muy positiva!",
        "footer": "Ofrecemos un servicio superior de alquiler de coches con conductor, especializado en traslados desde el aeropuerto hasta el centro de la ciudad y viceversa. También ofrecemos servicios de transporte de larga distancia. Con un equipo de profesionales experimentados, nos comprometemos a proporcionar un servicio puntual, eficiente y de alta calidad. Para obtener más información, por favor, contacte a nuestro servicio al cliente.",

        "timeErrorMessages": {
            "journeyTimeErr": "La hora de salida debe ser con al menos 24h de antelación",
            "returnTimeErr": "La hora de regreso debe ser con al menos 24h de antelación",
            "returnTimeErrPrec": "La hora de regreso debe ser posterior a la hora de salida"
        },
        

        "serviceData": [
            {
                "id": 1,
                "title": "Pagos en línea",
                "icon": "ri-bank-card-line",
                "desc": "Experimenta transacciones sin problemas con nuestro sistema de pagos en línea seguro y eficiente. Disfruta de un proceso sin complicaciones desde la reserva hasta el pago."
            },
            {
                "id": 2,
                "title": "Puntualidad",
                "icon": "ri-alarm-line",
                "desc": "El tiempo es esencial y nuestro servicio se enorgullece de ser puntual en todo momento. Confía en nosotros para llevarte a donde necesitas estar a tiempo."
            },
            {
                "id": 3,
                "title": "Monitoreo de vuelos",
                "icon": "ri-flight-takeoff-line",
                "desc": "Nuestros servicios de monitoreo de vuelos aseguran que siempre estamos actualizados con los detalles de tu vuelo. Experimenta un viaje sin estrés con actualizaciones oportunas."
            },
            {
                "id": 4,
                "title": "Conductores",
                "icon": "ri-car-line",
                "desc": "Nuestros conductores profesionales y corteses están aquí para brindarte un viaje cómodo y seguro. Viaja con personal experimentado."
            },
            {
                "id": 5,
                "title": "Encuentro y bienvenida",
                "icon": "ri-user-3-line",
                "desc": "Experimenta un servicio de encuentro y bienvenida cálido y acogedor que te hace sentir valorado desde el momento en que llegas. Una cara amigable te espera."
            },
            {
                "id": 6,
                "title": "Empresa confiable",
                "icon": "ri-shield-star-line",
                "desc": "Elige una empresa que representa confiabilidad y excelencia. Estamos comprometidos a proporcionar servicios que superen tus expectativas."
            }
        ],


        "steps": [
            {
                "title": "Ingresa tu ruta y selecciona el vehículo",
                "description": "Ingresa todos los datos requeridos en el campo de búsqueda y luego elige el vehículo deseado"
            },
            {
                "title": "Completa el formulario de reserva",
                "description": "Ingresa los detalles del pasajero principal, agrega extras si lo deseas. Procede al pago y recibe el comprobante"
            },
            {
                "title": "Encuentra a tu conductor fuera del aeropuerto",
                "description": "Recibirás los detalles de tu conductor 6 horas antes de la recogida y te estará esperando en el lugar con un cartel que indica el nombre"
            }
        ],
        "cityData": [
            { "image": Pisa, "city": "Pisa", "description": "Aeropuerto de Pisa -> Centro de la ciudad de Pisa", "fromAddress":"Aeroporto Internazionale di Pisa (PSA), Pisa, PI, Italia", "toAddress":"Pisa, PI, Italia" },
            { "image": Firenze, "city": "Florencia", "description": "Aeropuerto de Florencia -> Centro de la ciudad de Florencia", "fromAddress":"Aeroporto Amerigo Vespucci, Via del Termine, Firenze, FI, Italia", "toAddress":"Centro Storico, Firenze, FI, Italia" },
            { "image": Roma, "city": "Roma", "description": "Aeropuerto de Roma -> Centro de la ciudad de Roma", "fromAddress":"Aeroporto di Roma-Fiumicino 'Leonardo da Vinci' (FCO), Via dell'Aeroporto, Fiumicino, Roma, Italia", "toAddress":"Centro Storico, Roma, RM, Italia" },
            { "image": Milano, "city": "Milán", "description": "Aeropuerto de Milán -> Centro de la ciudad de Milán", "fromAddress":"Aeroporto di Milano-Linate (LIN), Viale Enrico Forlanini, Segrate, Milano, Italia", "toAddress":"Centrale, Milano, MI, Italia" },
            { "image": Catania, "city": "Catania", "description": "Aeropuerto de Catania -> Centro de la ciudad de Catania", "fromAddress":"Aeroporto di Catania, Via Fontanarossa, Catania, CT, Italia", "toAddress":"Centro, Catania, CT, Italia" },
            { "image": Bologna, "city": "Bolonia", "description": "Aeropuerto de Bolonia -> Centro de la ciudad de Bolonia", "fromAddress":"Aeroporto di Bologna, Via del Triumvirato, Bologna, BO, Italia", "toAddress":"Bologna, BO, Italia" }
        ],
        "carData": [
            {
                "id": 1,
                "carName": "Economy",
                "model": "Hasta 3 pasajeros",
                "imgUrl": img01,
                "capacity": "Acomoda a 3 personas",
                "luggage": "Apto para hasta 3 maletas medianas"
            },
            {
                "id": 2,
                "carName": "Business",
                "model": "Hasta 3 pasajeros",
                "imgUrl": img02,
                "capacity": "Acomoda a 3 personas",
                "luggage": "Apto para hasta 3 maletas medianas"
            },
            {
                "id": 3,
                "carName": "Mini Van",
                "model": "Hasta 7 pasajeros",
                "imgUrl": img03,
                "capacity": "Acomoda a 7 personas",
                "luggage": "Apto para hasta 7 maletas medianas"
            },
            {
                "id": 4,
                "carName": "Mini Bus",
                "model": "Hasta 8 pasajeros",
                "imgUrl": img04,
                "capacity": "Acomoda a 8 personas",
                "luggage": "Apto para hasta 8 maletas medianas"
            }
        ]
    }
}

export default data;