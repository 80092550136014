

import { Tab, Tabs } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import translations from '../../assets/data/translations'

const FormTabs = ({ tabValue, handleTabChange, language }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const lang = translations[language]
    return (
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange} 
        style={{marginBottom:"20px",marginTop:"-20px"}} 
        variant={isSmallScreen ? "scrollable" : "standard"}
        scrollButtons={isSmallScreen ? "auto" : false}
      >
        <Tab label={lang.oneWayForm} style={{ opacity: 1 }} sx={{ fontFamily: 'Roboto', fontWeight: 900, fontSize: '1.3rem' }}/>
        <Tab label={lang.returnForm} style={{ opacity: 1 }} sx={{ fontFamily: 'Roboto', fontWeight: 900, fontSize: '1.3rem' }}/>
      </Tabs>
    );
  };

  export default FormTabs;
