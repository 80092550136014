import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate  } from "react-router-dom";




const CityCard = ({ city, image, description, fromAddress, toAddress, setScrollPosition }) => {
  const navigate = useNavigate();

  const getNextDayDate = () => {
    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);
    const dd = String(nextDay.getDate()).padStart(2, '0');
    const mm = String(nextDay.getMonth() + 1).padStart(2, '0');
    const yyyy = nextDay.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  }

  const prepareTransferUrl = () => {
    let url = "/transfer?";
    url += `fromAddress=${encodeURIComponent(
      fromAddress
    )}&toAddress=${encodeURIComponent(
      toAddress
    )}&journeyDate=${encodeURIComponent(getNextDayDate())}&journeyTime=${encodeURIComponent(
      '12:00'
    )}&personNumber=${encodeURIComponent(
      1
    )}&tabValue=${encodeURIComponent(
      0
    )}`;
    navigate(url);
    setScrollPosition(0)
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ width: '100%', marginBottom: '2rem' }} elevation={8}>
        <CardActionArea onClick={prepareTransferUrl}>
          <CardMedia
            component="img"
            height="250rem"
            image={image}
            alt={city}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '1.4rem' }}>
              {city}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '1.4rem' }}>
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default CityCard;
