import React, {useState, useEffect} from "react";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import "../styles/about.css";
import HeroSlider from "../components/UI/HeroSlider";
import FindCarForm from "../components/UI/FindCarForm";
import TransferStepper from "../components/UI/TransferStepper";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import "../styles/about.css";
import { useLocation  } from "react-router-dom";
// import imgEconomy from '../assets/all-images/cars-img/economy.png';economy-sedan-removebg.png
import imgEconomy from '../assets/all-images/cars-img/resized_economy_sedan.png';
import imgBusiness from '../assets/all-images/cars-img/firstclass.png';
import imgMiniVan from '../assets/all-images/cars-img/standard_van.png';
import imgMiniBus from '../assets/all-images/cars-img/first_van.png';
import translations from '../assets/data/translations'

const Transfer = ({language, setScrollPosition}) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromAddressParam = searchParams.get("fromAddress");
  const toAddressParam = searchParams.get("toAddress");
  const journeyDateParam = searchParams.get("journeyDate");
  const JourneyTimeParam = searchParams.get("journeyTime");
  const returnDateParam = searchParams.get("returnDate");
  const returnTimeParam = searchParams.get("returnTime");
  const personNumberParam = searchParams.get("personNumber");
  const tabValueParam = searchParams.get("tabValue");
  // const language = searchParams.get("language");

  const [tabValue, setTabValue] = useState(Number(tabValueParam));
  const [personNumber, setPersonNumber] = useState(personNumberParam);
  const [fromAddress, setFromAddress] = useState(fromAddressParam);
  const [toAddress, setToAddress] = useState(toAddressParam);
  const [journeyDate, setJourneyDate] = useState(journeyDateParam)
  const [journeyTime, setJourneyTime] = useState(JourneyTimeParam)
  const [returnDate, setReturnDate] = useState(returnDateParam)
  const [returnTime, setReturnTime] = useState(returnTimeParam || '')
  const [fromAddressOptions, setFromAddressOptions] = useState([]);
  const [toAddressOptions, setToAddressOptions] = useState([]);

  const [activeStep, setActiveStep] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState('')

  const lang = translations[language].carData
  const [economyProps, setEconomyProps] = useState({
    image: imgEconomy,
    title: 'Berlina Economy',
    rating: '4.9',
    capacity: lang[0].capacity,
    luggage: lang[0].luggage,
    price: '',
  });
  const [businessProps, setBusinessProps] = useState({
    image: imgBusiness,
    title: 'Berlina Sedan',
    rating: '4.7',
    capacity: lang[1].capacity,
    luggage: lang[1].luggage,
    price: '',
  });
  const [miniVanProps, setMiniVanProps] = useState({
    image: imgMiniVan,
    title: 'Mini Van',
    rating: '4.6',
    capacity: lang[2].capacity,
    luggage: lang[2].luggage,
    price: '',
  });
  const [miniBusProps, setMiniBusProps] = useState({
    image: imgMiniBus,
    title: 'Mini Bus',
    rating: '4.8',
    capacity: lang[3].capacity,
    luggage: lang[3].luggage,
    price: '',
  });

  useEffect(() => {
    const lang = translations[language].carData;
    setEconomyProps(prevProps => ({...prevProps, capacity: lang[0].capacity, luggage: lang[0].luggage }));
    setBusinessProps(prevProps => ({...prevProps, capacity: lang[1].capacity, luggage: lang[1].luggage }));
    setMiniVanProps(prevProps => ({...prevProps, capacity: lang[2].capacity, luggage: lang[2].luggage }));
    setMiniBusProps(prevProps => ({...prevProps, capacity: lang[3].capacity, luggage: lang[3].luggage }));
  }, [language]);

  return (
    <Helmet title="Transfer">
      {/* ============= hero section =========== */}
      <section className="p-0 hero__slider-section" >
        <HeroSlider tabValue={tabValue}/>
        
        <div className="hero__form" style={{ opacity: 0.95, marginBottom: '3rem' }}>
          <Container className="transfer-container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Paper>
                  <FindCarForm
                    fromAddress={fromAddress}
                    setFromAddress={setFromAddress}
                    toAddress={toAddress}
                    setToAddress={setToAddress}
                    journeyDate={journeyDate}
                    setJourneyDate={setJourneyDate}
                    journeyTime={journeyTime}
                    setJourneyTime={setJourneyTime}
                    returnDate={returnDate}
                    setReturnDate={setReturnDate}
                    returnTime={returnTime}
                    setReturnTime={setReturnTime}
                    personNumber={personNumber}
                    setPersonNumber={setPersonNumber}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    setActiveStep = {setActiveStep}
                    fromAddressOptions={fromAddressOptions}
                    setFromAddressOptions={setFromAddressOptions}
                    toAddressOptions={toAddressOptions}
                    setToAddressOptions={setToAddressOptions}
                    language={language}
                    setScrollPosition={setScrollPosition}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <TransferStepper
                  fromAddress={fromAddress}
                  toAddress={toAddress}
                  journeyDate={journeyDate}
                  journeyTime={journeyTime}
                  returnDate={returnDate}
                  returnTime={returnTime}
                  personNumber={personNumber}
                  tabValue={tabValue}
                  economyProps={economyProps}
                  businessProps={businessProps}
                  miniVanProps={miniVanProps}
                  miniBusProps={miniBusProps}
                  setEconomyProps={setEconomyProps}
                  setBusinessProps={setBusinessProps}
                  setMiniVanProps={setMiniVanProps}
                  setMiniBusProps={setMiniBusProps}
                  selectedPlan = {selectedPlan}
                  setSelectedPlan = {setSelectedPlan}
                  activeStep = {activeStep}
                  setActiveStep = {setActiveStep}
                  language={language}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </section>
    </Helmet>
  );
};

export default Transfer;
