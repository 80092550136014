import React, { useState, useCallback } from "react";
import { Grid, TextField } from "@mui/material";
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import { DateTimePicker, DatePicker, TimePicker, LocalizationProvider, DesktopDateTimePicker, DesktopDatePicker, DesktopTimePicker} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { errorMessageClass } from "./AdressField";
import translations from '../../assets/data/translations'
import _ from 'lodash';

const StyledDateTimePicker = styled(DateTimePicker)`
  .MuiInputBase-input {
    height: 2.4375em;
    /* Add your custom styles here */
  }


  /* Resetting styles for the label */
  .MuiFormLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.54);  /* Change to default label color */
  }

  /* Resetting styles for the input field */
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);  /* Change to default border color */
  }
`;

const StyledDatePicker = styled(DesktopDatePicker)`
  .MuiInputBase-input {
    height: 2.4375em;
    /* Add your custom styles here */
  }

  /* Resetting styles for the label */
  .MuiFormLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.54);  /* Change to default label color */
  }

  /* Resetting styles for the input field */
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);  /* Change to default border color */
  }
  width: 100% !important;
`;

const StyledTimePicker = styled(DesktopTimePicker)`
  .MuiInputBase-input {
    height: 2.4375em;
    /* Add your custom styles here */
  }

  /* Resetting styles for the label */
  .MuiFormLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.54);  /* Change to default label color */
  }

  /* Resetting styles for the input field */
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);  /* Change to default border color */
  }
  width: 100% !important;
`;

const StyledTextField = styled(TextField)({
  "& input": {
    height: '2.4375em !important',
  },
});

const DateTimeField = ({ label, type, dateTime, mdValue, setDateTime, error = false, minDate, language, selectedDateProp, resetJourneyTime, resetReturnTime, artype }) => {
  const handleChange = (event) => {
    const selectedTime = event.target.value;
    const currentTime = dayjs().format('HH:mm');
    console.log(selectedTime)
    console.log(currentTime)
    console.log(selectedDateProp)
    console.log(dayjs(), 'day')
    console.log(dayjs(selectedDateProp).isSame(dayjs(), 'day'))
    const isToday = dayjs(selectedDateProp).isSame(dayjs(), 'day');
    if ((isToday && selectedTime >= currentTime) || (!isToday)) {
      setDateTime(selectedTime);
    } else {
      console.log("Selected time is in the past or on a different day. Ignoring the update.");
      alert("Selected time is in the past or on a different day. Ignoring the update.");
    }
  };

  const lang = translations[language]
  const [pickerKey, setPickerKey] = useState(Date.now());

  const debouncedOnChange = useCallback(
    _.debounce((newValue, setDateTime, setPickerKey) => {
      let formattedValue;
      if (type === 'date') {
        const date = dayjs(newValue).format('YYYY-MM-DD');
        formattedValue = date;
      } else if (type === 'datetime-local') {
        const datetime = dayjs(newValue).format('YYYY-MM-DDTHH:mm');
        formattedValue = datetime;
      }
      const today = dayjs().startOf('day');
      const selectedDate = dayjs(newValue).startOf('day');
      const isTodayOrFutureDate = selectedDate.isSame(today) || selectedDate.isAfter(today);
      console.log(today);
      console.log(selectedDate);

      if (formattedValue.startsWith('0')) {
        console.log("Invalid year, ignoring the update.");
        return;
      }

      if (isTodayOrFutureDate) {
        console.log("sono ioo",formattedValue);
        setDateTime(formattedValue);
        if( artype === 'andata'){
          resetReturnTime()
          resetJourneyTime()
        }else if( artype === 'andataOneWay' ) {
          resetJourneyTime()
        }else{
          resetReturnTime()
        }

        // artype === 'andata' ? (resetReturnTime(), resetJourneyTime()) : resetReturnTime()

      } else {
        console.log("Selected date is in the past. Ignoring the update.");
        setPickerKey(Date.now());
        if( artype === 'andata'){
          resetReturnTime()
          resetJourneyTime()
        }else if( artype === 'andataOneWay' ) {
          resetJourneyTime()
        }else{
          resetReturnTime()
        }
        alert("Selected date is in the past. Ignoring the update.")
      }
    }, 0),
  []);

  let Picker;
  switch(type) {
    case "date":
      Picker = StyledDatePicker;
      break;
    case "time":
      // No picker for time, we will use TextField directly
      break;
    default:
      Picker = StyledDateTimePicker;
  }

  let format;
  if (type === "date") {
    format = "DD/MM/YYYY";
  } else if (type === "datetime-local") {
    format = "DD/MM/YYYY HH:mm";
  }

  return (
    <Grid item xs={12} sm={6} md={6} lg={mdValue}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {type === "time" ? (
          <StyledTextField
            type="time"
            id={label}
            label={label}
            value={dateTime && !isNaN(dateTime) ? dayjs(dateTime).format('HH:mm') : ""}
            placeholder="HH:mm"
            onChange={handleChange}
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        ) : (
          <Picker
          key={pickerKey}
          label={label}
          value={dayjs(dateTime)}
          onChange={(newValue) => debouncedOnChange(newValue, setDateTime, setPickerKey)}
          ampm={false}
          format={format}
          minDate={minDate ? dayjs(minDate) : dayjs()}
          textField={(params) => {
            <TextField {...params} value={dateTime ? dayjs(dateTime).format(format) : ''} />
          }}
          InputLabelProps={{ shrink: true }} 
        />
        )}
      </LocalizationProvider>
      {error && <div style={errorMessageClass}>{lang.required}</div>}
    </Grid>
  );
};

export default DateTimeField;