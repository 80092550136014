import React, { useRef, useState, useEffect } from "react";
import { Button, Drawer, List, ListItem, ListItemText, Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../styles/header.css";
import logo from '../../assets/all-images/logo_new.png'
import translations from '../../assets/data/translations.js'

import italianFlag from '../../assets/all-images/italianFlag.png';
import englishFlag from '../../assets/all-images/englishFlag.png';
import spanishFlag from '../../assets/all-images/spanishFlag.png';

const navLinks = [
  {
    path: "#findCarForm",
    display: "Home",
  },
  {
    path: "#services",
    display: "Services",
  },

  {
    path: "#cars",
    display: "Plans",
  },
  {
    path: "#howWorks",
    display: "How It Works",
  },
  {
    path: "#mttfooter",
    display: "Contacts"
  }
];

const Header = ({language, setLanguage}) => {
  const menuRef = useRef(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const onScroll = () => {
      const scrollPosition = window.scrollY > 50;
      setIsScrolled(scrollPosition);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
}, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  return (
    <header className="header">
      
      {/* ========== main navigation =========== */}

      <div className={`main__navbar ${isScrolled ? 'solid' : ''}`}>
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <a href={location.pathname === '/home' ? '#findCarForm' : `/home#findCarForm`}>
              <img src={logo} alt="Logo" style={{ width: '100px', height: 'auto', scale: '1.3' }} />
            </a>



            {isMobile ? (
              <>
                <Select
                  value={language}
                  onChange={(event) => setLanguage(event.target.value)}
                  displayEmpty
                  style={{ color: 'white' }} // Customize this to match your design
                >
                  <MenuItem value="Italian"><img src={italianFlag} alt="Italian" width="38rem" /></MenuItem>
                  <MenuItem value="English"><img src={englishFlag} alt="English" width="38rem" /></MenuItem>
                  <MenuItem value="Spanish"><img src={spanishFlag} alt="Spanish" width="38rem" /></MenuItem>
                </Select>
                <span className="mobile__menu">
                  <Button onClick={() => {
                    setDrawerOpen(true)
                  }}>
                    <i className="ri-menu-line"></i>
                  </Button>
                </span>
                <Drawer anchor="top" open={drawerOpen} onClose={() => {setDrawerOpen(false)}}>
                  <List>
                    {navLinks.map((item, index) => (
                      <ListItem
                        button
                        component='a'
                        href={location.pathname === '/home' ? item.path : `/home${item.path}`}
                        key={index}
                        onClick={() => {setDrawerOpen(false)}}
                      >
                        <ListItemText primary={translations[language][item.display]} />
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
              </>
            ) : (
              <div className="navigation" onClick={toggleMenu}>
                <div className="menu" ref={menuRef}>
                {navLinks.map((item, index) => (
                  <MenuItem
                      // to={item.path}
                      // component={NavLink}
                      className={(navClass) =>
                          navClass.isActive ? "nav__active nav__item" : "nav__item"
                      }
                      style={{ color: isScrolled ? 'white' : 'white', fontWeight: 700, fontSize: '1.3rem', padding: 0}}
                      key={index}
                  >
                    <a href={location.pathname === '/home' ? item.path : `/home${item.path}`} style={{textDecoration: 'none', color: 'inherit', paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '0.6rem', paddingBottom: '0.6rem'}}>
                      {translations[language][item.display]}
                    </a>
                  </MenuItem>
                ))}

                </div>
              </div>
            )}
            {/* <div className="nav__right">
              <div className="search__box">
                <input type="text" placeholder="Search" />
                <span>
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </div> */}

            <div className="nav__right">
              <Select
                value={language}
                onChange={(event) => setLanguage(event.target.value)}
                displayEmpty
                style={{ color: 'white' }} // Customize this to match your design
              >
                <MenuItem value="Italian"><img src={italianFlag} alt="Italian" width="38rem" /></MenuItem>
                <MenuItem value="English"><img src={englishFlag} alt="English" width="38rem" /></MenuItem>
                <MenuItem value="Spanish"><img src={spanishFlag} alt="Spanish" width="38rem" /></MenuItem>
              </Select>
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
