import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Container, Row, Col } from "reactstrap";
import { useMediaQuery } from '@mui/material';
import translations from '../../assets/data/translations'

const StyledDiv = styled('div')({
    margin: '0',  
    padding: '20px 50px', 
    borderRadius: '10px',  
    // backgroundColor: '#0476F1',
    backgroundColor: '#024d9f',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
});


function CustomStepper({ activeStep = 0, language }) {

    const matches = useMediaQuery(theme => theme.breakpoints.down('md'));
    const steps = translations[language].steps;

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 0,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    }));


    function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
            <Check className="QontoStepIcon-completedIcon" />
        ) : (
            <div className="QontoStepIcon-circle" />
        )}
        </QontoStepIconRoot>
    );
    }

    QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    };


    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(121 135 175) 0%, rgb(13 115 243) 50%, rgb(62 108 255) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(121 135 175) 0%, rgb(13 115 243) 50%, rgb(62 108 255) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 0,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
        'linear-gradient( 136deg, rgb(121 135 175) 0%, rgb(15 90 203) 50%, rgb(174 187 255) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
        'linear-gradient( 136deg, rgb(121 135 175) 0%, rgb(15 90 203) 50%, rgb(174 187 255) 100%)',
    }),
    }));

    function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
    }

    ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
    };




  return (
    <section id="howWorks">
      <Container>
        <Row>
          <Col lg="12" className="mb-4 text-center">
            <h2 className="section__title" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '3rem', paddingTop: '0' }}>{translations[language].howItWorks}</h2>
          </Col>
            <StyledDiv>
            <Stepper alternativeLabel={matches ? false : true} orientation={matches ? 'vertical' : 'horizontal'} activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((step, index) => (
                <Step key={step.title}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <div>
                        <h3 style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '1.7rem', margin:'1.5rem',color: '#fff' }}>{step.title}</h3>
                        <p style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '1.3rem', textAlign: 'left', margin:'1.5rem', color: '#CAF0F8'}}>{step.description}</p>
                    </div>
                    </StepLabel>
                </Step>
                ))}
            </Stepper>
            </StyledDiv>
        </Row>
      </Container>
    </section>
    );
}

CustomStepper.propTypes = {
  activeStep: PropTypes.number,
};

export default CustomStepper;
