
const env_url = {
    "url":"https://www.easytransfer.vip/api/",
    "token":"Token d1288aa24511eecb4d8da1bd3b87bd088712139a",
    "pdf_url": "https://storage.googleapis.com/easytransfer_storage/static/ext-web/mytransfertrip/termini-servizi.pdf"
}



// const env_url = {
//     "url":"https://www.nexus.cab/api/",
//     "token":"Token 58400cd1e18da8a3e41ae265774aca6be1d4c1d8",
//     "pdf_url": "https://storage.googleapis.com/easytransfer_storage/static/ext-web/mytransfertrip/termini-servizi.pdf"
// }

export default env_url