import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SmallCarCard from './SmallCarCard';
import CarCard from './CarCard';
import { Checkbox, FormControlLabel, Radio, RadioGroup, Card } from '@mui/material';
import { styled } from '@mui/system';
import DepartureForm from './DepartureForm';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import ScrollToTop from './ScrollToTop';
import env_url from '../../assets/data/env_url'


const StyledTextField = styled(TextField)({
  "& input": {
    height: '2.4375em !important',
  },
});

export default function TransferStepper({
  fromAddress, 
  toAddress, 
  journeyDate, 
  journeyTime, 
  returnDate, 
  returnTime, 
  personNumber, 
  tabValue, 
  economyProps, 
  businessProps, 
  miniVanProps, 
  miniBusProps, 
  setEconomyProps,
  setBusinessProps,
  setMiniVanProps,
  setMiniBusProps,
  selectedPlan,
  setSelectedPlan,
  activeStep,
  setActiveStep,
  language
}) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);


  const fetchPrices = async () => {
    try {
      const response = await axios.post(
        env_url.url + 'v1/external/pricing',
        {
          fromAddress: fromAddress,
          toAddress: toAddress,
          personNumber: personNumber,
          tabValue: tabValue,
          journeyTime: journeyTime,
          returnTime: returnTime, 
        },
        {
          headers: {
            Authorization: env_url.token
          }
        }
      );
  
      if (response.data.success) {
        const { economy, luxury, minivan, minibus } = response.data;
        setEconomyProps({ ...economyProps, price: `EUR ${economy.toFixed(2)}` });
        setBusinessProps({ ...businessProps, price: `EUR ${luxury.toFixed(2)}` });
        setMiniVanProps({ ...miniVanProps, price: `EUR ${minivan.toFixed(2)}` });
        setMiniBusProps({ ...miniBusProps, price: `EUR ${minibus.toFixed(2)}` });
      }
    } catch (error) {
      console.error('Error fetching prices:', error);
    }
  };
  

  
  const [formValues, setFormValues] = useState({
    flightNumber: '',
    landingTime: '',
    returnFlightNumber: '',
    returnLandingTime: '',
    seggiolino: false,
    seggiolinoPerNeonati: false,
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    phonePrefix: '',
    note: '',
    termini: false,
  });

  const [errors, setErrors] = useState({});

  const handleNext = async (plan) => {

    if (activeStep === 2) {
      const newErrors = validateForm();
      console.warn("newErrors", newErrors)
      console.warn("formValues", formValues)
      const hasErrors = Object.values(newErrors).some((value) => value === true);
      if (!hasErrors) {
        setLoading(true)
        const allValues = {
          ...formValues,
          fromAddress,
          toAddress,
          journeyDate,
          journeyTime,
          returnDate,
          returnTime,
          personNumber,
          tabValue,
          selectedPlan: selectedPlan.toUpperCase(), 
          transferUrl: window.location.href,
        };
        console.log(allValues);
        try{
          const response = await axios.post(
            env_url.url + 'v1/external/after/payment',
            allValues,
            {
              headers: {
                Authorization: env_url.token
              }
            }
          );
          window.location.href = response.data.urlStripe;
          setLoading(false)
          console.log(response.data);
        }catch (error) {
          console.error('Error making form request: ', error)
          setLoading(false)
        }
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      setErrors(newErrors);
    } else {
      console.warn("PLAN TYPE HEREEE", plan)
      setSelectedPlan(plan)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    fetchPrices(); 
  }, [fromAddress, toAddress, tabValue, personNumber, fromAddress, toAddress, journeyDate, journeyTime, returnDate, returnTime]);



  const getContent = () => {
    switch (activeStep) {
      case 1:
        if(personNumber <= 3){
          return (
            <>
              <CarCard {...economyProps} handleNext={() => handleNext('economy')} language={language}/>
              <Box sx={{ display: 'flex', justifyContent: 'center', padding: '2em' }}>
                <Typography variant="h6">Ti senti curioso? Scopri di piu</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <SmallCarCard {...businessProps} handleNext={() => handleNext('business')} language={language}/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallCarCard {...miniVanProps} handleNext={() => handleNext('minivan')} language={language}/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallCarCard {...miniBusProps} handleNext={() => handleNext('minibus')} language={language}/>
                </Grid>
              </Grid>
            </>
          );
        } else if (personNumber > 3 && personNumber <= 7) {
          return (
            <>
              <CarCard {...miniVanProps} handleNext={() => handleNext('minivan')} language={language}/>
              <Box sx={{ display: 'flex', justifyContent: 'center', padding: '2em' }}>
                <Typography variant="h6">Ti senti curioso? Scopri di piu</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <SmallCarCard {...miniBusProps} handleNext={() => handleNext('minibus')} language={language}/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallCarCard {...economyProps} disabled={true} handleNext={() => handleNext('economy')} language={language}/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallCarCard {...businessProps} disabled={true} handleNext={() => handleNext('business')} language={language}/>
                </Grid>
              </Grid>
            </>
          );
        } else if (personNumber > 7) {
          return (
            <>
              <CarCard {...miniBusProps} handleNext={() => handleNext('minibus')} language={language}/>
              <Box sx={{ display: 'flex', justifyContent: 'center', padding: '2em' }}>
                <Typography variant="h6">Ti senti curioso? Scopri di piu</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <SmallCarCard {...miniVanProps} disabled={true} handleNext={() => handleNext('minivan')} language={language}/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallCarCard {...economyProps} disabled={true} handleNext={() => handleNext('economy')} language={language}/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallCarCard {...businessProps} disabled={true} handleNext={() => handleNext('business')} language={language}/>
                </Grid>
              </Grid>
            </>
          );
        }
      case 2:
        return (
          <>
            <ScrollToTop />
            <DepartureForm 
              formValues={formValues} 
              setFormValues={setFormValues} 
              errors={errors} 
              selectedPlan={selectedPlan}
              fromAddress={fromAddress}
              toAddress={toAddress}
              journeyDate={journeyDate}
              journeyTime={journeyTime}
              returnDate={returnDate}
              returnTime={returnTime}
              personNumber={personNumber}
              tabValue={tabValue}
              language = {language}
            />
          </>
          
        )
        
      default:
        return null;
    }
  };

  const validateForm = () => {
    const newErrors = {
      flightNumber: false,
      landingTime: false,
      returnFlightNumber: false,
      returnLandingTime: false,
      seggiolino: false,
      seggiolinoPerNeonati: false,
      termini: formValues.termini === false,
      salutation: formValues.salutation === '',
      firstName: formValues.firstName === '',
      lastName: formValues.lastName === '',
      email: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email),
      phoneNumber: formValues.phoneNumber === '',
      phonePrefix: formValues.phonePrefix === '',
      note: false,
    };

    return newErrors;
  };

  return (
    <Box>
      <MobileStepper
        variant="progress"
        steps={4}
        position="static"
        activeStep={activeStep}
        sx={{
          flexGrow: 1,
          width: '100%',
          backgroundColor: 'transparent',
          '& .MuiLinearProgress-root': {
            width: '100%',
            height: '1rem',
            borderRadius: '1rem',
          },
          '.MuiLinearProgress-bar': { backgroundColor: '#2563EB' },
        }}
      />

      {getContent()}
      {activeStep === 2 && (
        <Card elevation={3} sx={{ display: 'flex', justifyContent: 'space-between', padding: '2em', margin: '2em 1.3rem', alignItems: 'center', backgroundColor: '#024d9f', borderRadius: '14px' }}>

          <Typography variant="h6" sx={{color: '#fff'}}>
            {selectedPlan === 'economy' && economyProps.price}
            {selectedPlan === 'business' && businessProps.price}
            {selectedPlan === 'minivan' && miniVanProps.price}
            {selectedPlan === 'minibus' && miniBusProps.price}  
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              size="medium"
              onClick={handleBack}
              disabled={activeStep === 1}
              sx={{ margin: '0 1em', backgroundColor: '#2563EB', color: '#fff' }}
              variant="outlined"
              startIcon={<KeyboardArrowLeft />}
            >
              Back
            </Button>
            <Button
              size="medium"
              onClick={handleNext}
              disabled={activeStep === 3}
              sx={{ margin: '0 1em', backgroundColor: '#2563EB', color: '#fff' }}
              variant="contained"
              endIcon={<KeyboardArrowRight />}
            >
              Next
            </Button>
          </Box>
        </Card>
      )}
      <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Box>
  );
}
