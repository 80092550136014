import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import translations from '../../assets/data/translations'

const quickLinks = [
  {
    path: "#findCarForm",
    display: "Home",
  },

  {
    path: "#photos",
    display: "PopularCitiesFooter",
  },

  {
    path: "#services",
    display: "Services",
  },
  {
    path: "#testimonial",
    display: "TestimonialsFooter",
  },

  {
    path: "#howWorks",
    display: "How It Works",
  },
];

const Footer = ({language}) => {
  const date = new Date();
  const year = date.getFullYear();
  const lang = translations[language]
  return (
    <footer className="footer" id="mttfooter">
      <Container>
        <Row>
          <Col lg="5" md="12" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <i className="ri-car-line"></i>
                  <span style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.4rem'}}>
                    My Transfer Trip
                  </span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '1.1rem', marginTop: '1.7rem'  }}>
              {lang.footer}
            </p>
          </Col>

          <Col lg="3" md="6" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.4rem' }}>{lang.quickLinks}</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.1rem' }}>
                    <a href={item.path} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '1.1rem' }}>{translations[language][item.display]}</a>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="4" md="6" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.4rem' }}>{lang.headOffice}</h5>
              <p className="office__info" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '1.1rem' }}>{lang.streetName}</p>
              <p className="office__info" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '1.1rem' }}>{lang.officePhone}</p>

              <p className="office__info" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '1.1rem' }}>{lang.officeMail}</p>

              <p className="office__info" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '1.1rem' }}>{lang.officeTime}</p>
            </div>
          </Col>

          {/* <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.4rem' }}>Newsletter</h5>
              <p className="section__description" style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: '1.1rem' }}>Subscribe our newsletter</p>
              <div className="newsletter">
                <input type="email" placeholder="Email" />
                <span>
                  <i className="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col> */}

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i className="ri-copyright-line"></i>Copyright {year}, La Sicilia Tour. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
