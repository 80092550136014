// AddressField.js
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import axios from 'axios';
import AddressAutocomplete from "./AddressAutocomplete";
import translations from '../../assets/data/translations'
import env_url from '../../assets/data/env_url'

export const errorMessageClass = {
    color: "red",
    fontSize: "1rem",
  };

const AddressField = ({ label, type, address, setAddress, setAddressOptions, adressOptions, fromAddress, toAddress, error, language }) => {
  const [isLoading, setIsLoading] = useState(false);
  const lang = translations[language]
  if (typeof(address) === 'string' && type=='from'){
    address = {start_point: address}
  }else if(typeof(address) === 'string' && type=='to'){
    address = {end_point: address}
  }
  const handleInputChange = async (event, value, reason) => {
    // console.log('handleInputChange called:', { event, value, reason }); #attenzione quaa
    if (value !== 'undefined' && value !== address && reason != 'reset'){
        if (reason === 'input') { 
            // setAddress(value)
            setIsLoading(true); 
            try {
              const payload = { ricerca: value, andata: null };
      
              if (type === 'to' && fromAddress !== null) {
                if(typeof(fromAddress) === 'string'){
                    payload.andata = fromAddress
                }else{
                    payload.andata = fromAddress['start_point']
                }
              }
              const response = await axios.post(env_url.url + 'all/user/route', 
                payload,
                { headers: { Authorization: env_url.token } }  
              );
              if (response.data.allStaticRoute.some(route => route.start_point === value)) {
                setAddress(value);
              }      
              setAddressOptions(response.data.allStaticRoute);   
            } catch (error) {
              console.error('Failed to fetch addresses:', error);
            } finally {
              setIsLoading(false);  
            }
          } else if (reason == 'clear'){
            setIsLoading(true); 
            try {
              console.log("fromAddress",fromAddress)
              const payload = { ricerca: null, andata: null, elimina: null };
              if (type === 'from' && fromAddress !== null) {
                if(typeof(fromAddress) === 'string'){
                    payload.elimina = fromAddress
                }else{
                    payload.elimina = fromAddress['start_point']
                }
              }
              const response = await axios.post(env_url.url + 'all/user/route', 
                payload,
                { headers: { Authorization: env_url.token } }  
              );
              if (response.data.allStaticRoute.some(route => route.start_point === value)) {
                setAddress(value);
              }      
              setAddressOptions(response.data.allStaticRoute);   
            } catch (error) {
              console.error('Failed to fetch addresses:', error);
            } finally {
              setIsLoading(false);  
            }
          }
    }
  };

  const handleFocus = async (event, value, reason) => {
    // console.log('handleInputChange called doppia chiamata:', { event, value, reason, type });
    if (fromAddress && type != 'from') {
      setIsLoading(true);
      try {
        const payload = { ricerca: null, andata: null };
        const currentPath = window.location.pathname;

        if (currentPath === '/home'){
          payload.andata = (typeof(fromAddress) === 'string' || fromAddress === undefined) ? fromAddress : fromAddress['start_point'];
        } else if (type === 'to'){
          console.log(toAddress)
          if (typeof(toAddress) === 'string' && toAddress !== undefined && toAddress !== null) {
              payload.andata = toAddress;
          } else if (typeof(fromAddress) === 'string' || fromAddress === undefined || fromAddress === null) {
              payload.andata = fromAddress;
          } else {
              payload.andata = toAddress['end_point'];
          }
        } else {
          payload.andata = (typeof(fromAddress) === 'string' || fromAddress === undefined || fromAddress === null) ? fromAddress : fromAddress['start_point'];
        }
        payload.ricerca = payload.ricerca == undefined ? null : value
        console.log(payload)
        const response = await axios.post(env_url.url + 'all/user/route', 
          payload,
          { headers: { Authorization: env_url.token } }  
        );
        setAddressOptions(response.data.allStaticRoute);
      } catch (error) {
        console.error('Failed to fetch addresses:', error);
      } finally {
        setIsLoading(false);  
      }
    }
  };

  return (
    <Grid item xs={12} sm={6} md={6}>
      <AddressAutocomplete
        type={type}
        value={address}
        options={adressOptions}
        onInputChange={handleInputChange}
        label={label}
        isLoading={isLoading}
        onFocus={handleFocus}
        onChange={(event, newValue) => {
          console.log(event)
          setAddress(newValue);
        }}
      />
      {error && <div style={errorMessageClass}>{lang.required}</div>}
    </Grid>
  );
};

export default AddressField;
