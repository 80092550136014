import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ scrollPosition = 0 }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, scrollPosition);
    }, [pathname, scrollPosition]);

    return null;
}

export default ScrollToTop;
  