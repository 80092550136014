import React, {useState} from "react";

import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/Helmet";

import { Container, Row, Col } from "reactstrap";
import FindCarForm from "../components/UI/FindCarForm";
import ServicesList from "../components/UI/ServicesList";
import carData from "../assets/data/carData";
import CarItem from "../components/UI/CarItem";
import Testimonial from "../components/UI/Testimonial";
import CustomStepper from '../components/UI/CustomStepper';
import CityCard from '../components/UI/CityCard';

import translations from '../assets/data/translations'


const Home = ({language, setLanguage, setScrollPosition}) => {
  const [tabValue, setTabValue] = useState(0);
  const [activeStep, setActiveStep] = useState(1);
  const [personNumber, setPersonNumber] = useState('')
  const [fromAddress, setFromAddress] = useState(null);
  const [toAddress, setToAddress] = useState(null);
  const [journeyDate, setJourneyDate] = useState('')
  const [journeyTime, setJourneyTime] = useState('')
  const [returnDate, setReturnDate] = useState('')
  const [returnTime, setReturnTime] = useState('')
  const [fromAddressOptions, setFromAddressOptions] = useState([]);
  const [toAddressOptions, setToAddressOptions] = useState([]);
  const lang = translations[language];
  const cityData = translations[language].cityData;
  const carData = translations[language].carData;
  return (
    <Helmet title="Home">
      {/* ============= hero section =========== */}
      <section className="p-0 hero__slider-section" id="findCarForm">
        <HeroSlider tabValue={tabValue}/>

        <div className="hero__form" style={{ opacity: 0.95 }}>
          <Container>
            <Row className="form__row">
              <Col lg="8" md="8" sm="12">
                <FindCarForm 
                  tabValue={tabValue} 
                  setTabValue={setTabValue} 
                  setActiveStep={setActiveStep}
                  personNumber={personNumber}
                  setPersonNumber={setPersonNumber}
                  fromAddress={fromAddress}
                  setFromAddress={setFromAddress}
                  toAddress={toAddress}
                  setToAddress={setToAddress}
                  journeyDate={journeyDate}
                  setJourneyDate={setJourneyDate}
                  journeyTime={journeyTime}
                  setJourneyTime={setJourneyTime}
                  returnDate={returnDate}
                  setReturnDate={setReturnDate}
                  returnTime={returnTime}
                  setReturnTime={setReturnTime}
                  fromAddressOptions={fromAddressOptions}
                  setFromAddressOptions={setFromAddressOptions}
                  toAddressOptions={toAddressOptions}
                  setToAddressOptions={setToAddressOptions}
                  language={language}
                  setScrollPosition={setScrollPosition}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* =========== about section ================ 
      <AboutSection /> */}
      {/* ========== services section ============ */}
      <section id="services">
        <Container>
          <Row>
          <Col lg="12" className="mb-5 text-center">
            <h6 className="section__subtitle" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '2rem' }}> {lang.seeOur} </h6>
            <h2 className="section__title" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '3rem' }}>{lang.popularServices}</h2>
          </Col>


            <ServicesList language={language}/>
          </Row>
        </Container>
      </section>
      {/* =========== car offer section ============= */}
      <section id="cars">
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5">
              <h2 className="section__title" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '3rem' }}>{lang.comfortAndSafety}</h2>
              <h6 className="section__subtitle" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '2rem' }}>{lang.licensedVehicles}</h6>

            </Col>

            {carData.slice(0, 4).map((item) => (
              <CarItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
      {/* =========== become a driver section ============  <BecomeDriverSection /> */}
      

      <CustomStepper activeStep={2} language={language} />


      <section id="photos">
        <Container>
          <Row>

            <Col lg="12" className="text-center mb-5">
                <h2 className="section__title" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '3rem' }}>{lang.popularCities}</h2>
                <h6 className="section__subtitle" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '2rem' }}>{lang.popularCitiesDex}</h6>

            </Col>
            {cityData.map((item) => (
              <Col lg="4" md="6" key={item.city}>
                <CityCard city={item.city} image={item.image} description={item.description} fromAddress={item.fromAddress} toAddress={item.toAddress} setScrollPosition={setScrollPosition}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* =========== testimonial section =========== */}
      <section id="testimonial">
        <Container>
          <Row>
            <Col lg="12" className="mb-4 text-center">
              <h6 className="section__subtitle" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '2rem' }}>{lang.ourClientSays}</h6>
              <h2 className="section__title" style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '3rem' }}>{lang.testimonials}</h2>
            </Col>

            <Testimonial language={language}/>
          </Row>
        </Container>
      </section>

      {/* =============== blog section =========== 
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Explore our blogs</h6>
              <h2 className="section__title">Latest Blogs</h2>
            </Col>

            <BlogList />
          </Row>
        </Container>
      </section>
      */}
    </Helmet>
  );
};

export default Home;
