export const prefixes = [
    { label: "+355 (AL)", value: "+355" },
    { label: "+374 (AM)", value: "+374" },
    { label: "+43 (AT)", value: "+43" },
    { label: "+32 (BE)", value: "+32" },
    { label: "+387 (BA)", value: "+387" },
    { label: "+359 (BG)", value: "+359" },
    { label: "+357 (CY)", value: "+357" },
    { label: "+420 (CZ)", value: "+420" },
    { label: "+45 (DK)", value: "+45" },
    { label: "+372 (EE)", value: "+372" },
    { label: "+358 (FI)", value: "+358" },
    { label: "+33 (FR)", value: "+33" },
    { label: "+49 (DE)", value: "+49" },
    { label: "+30 (GR)", value: "+30" },
    { label: "+36 (HU)", value: "+36" },
    { label: "+354 (IS)", value: "+354" },
    { label: "+91 (IN)", value: "+91" },
    { label: "+61 (AU)", value: "+61" },
    { label: "+353 (IE)", value: "+353" },
    { label: "+39 (IT)", value: "+39" },
    { label: "+371 (LV)", value: "+371" },
    { label: "+423 (LI)", value: "+423" },
    { label: "+370 (LT)", value: "+370" },
    { label: "+352 (LU)", value: "+352" },
    { label: "+356 (MT)", value: "+356" },
    { label: "+377 (MC)", value: "+377" },
    { label: "+31 (NL)", value: "+31" },
    { label: "+47 (NO)", value: "+47" },
    { label: "+48 (PL)", value: "+48" },
    { label: "+351 (PT)", value: "+351" },
    { label: "+40 (RO)", value: "+40" },
    { label: "+378 (SM)", value: "+378" },
    { label: "+381 (RS)", value: "+381" },
    { label: "+421 (SK)", value: "+421" },
    { label: "+386 (SI)", value: "+386" },
    { label: "+34 (ES)", value: "+34" },
    { label: "+46 (SE)", value: "+46" },
    { label: "+41 (CH)", value: "+41" },
    { label: "+44 (GB)", value: "+44" },
    { label: "+1 (US)", value: "+1" },
    { label: "+380 (UA)", value: "+380" },
  ];
  