import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import Transfer from "../pages/Transfer";

const Routers = ({language, setLanguage, setScrollPosition}) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home language={language} setLanguage={setLanguage} setScrollPosition={setScrollPosition}/>} />
      <Route path="/transfer" element={<Transfer language={language} setLanguage={setLanguage} setScrollPosition={setScrollPosition}/>} />
      <Route path="*" element={<NotFound />} />


      {/* <Route path="/about" element={<About />} />
      <Route path="/cars" element={<CarListing />} />
      <Route path="/cars/:slug" element={<CarDetails />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      <Route path="/contact" element={<Contact />} /> */}
    </Routes>
  );
};

export default Routers;
